import dynamic from 'next/dynamic';
import Image from 'next/image';

import { WithLink } from '@/modules/layout/components/WithLink';
import { FCC } from '@/types/common';

import { BannersUspSectionSlideMobileProps } from '../../services/types';

const ChevronRightIcon = dynamic(
  async () => import('@lib/theme/svg/chevron-right-sm-icon.svg'),
  { ssr: false }
);

export const BannersUspSectionSlideMobile: FCC<
  BannersUspSectionSlideMobileProps
> = ({
  bannerBackgroundColor,
  title,
  ctaLabel,
  iconUrl,
  iconBackgroundColor,
  link,
}) => (
  <WithLink href={link}>
    <div
      className="flex h-[88px] overflow-hidden rounded-xl"
      style={{
        backgroundColor: bannerBackgroundColor,
      }}
    >
      <div className="m-4 flex w-full items-center justify-between">
        <div className="flex items-center">
          <div
            style={{
              backgroundColor: iconBackgroundColor || 'white',
            }}
            className="mr-4 flex size-9 items-center justify-center rounded-full"
          >
            {iconUrl && (
              <div className="size-5">
                <Image alt="usp icon" src={iconUrl} width={20} height={20} />
              </div>
            )}
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-750">{title}</h3>
            <h4 className="mt-1 text-xs text-gray-750">{ctaLabel}</h4>
          </div>
        </div>
        {link && (
          <ChevronRightIcon
            className="ml-4"
            width={20}
            height={20}
            style={{
              color: iconBackgroundColor || 'white',
            }}
          />
        )}
      </div>
    </div>
  </WithLink>
);
