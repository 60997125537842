import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import {
  createDynamicApiGatewayClient,
  serializeApiGatewayClient,
} from '@/config/serverSideConfig';
import { cn } from '@/core/ui/utils';
import { sendHomepageViewEvent } from '@/modules/analytics/eventHandlers/sendHomepageViewEvent';
import { getDelioServerSideProps } from '@/modules/auth/server/getDelioServerSideProps';
import { DynamicSections } from '@/modules/home/components/DynamicSections/DynamicSections';
import { HomePageSkeleton } from '@/modules/home/components/HomePageSkeleton';
import { UvpSection } from '@/modules/home/components/UvpSection';
import {
  SECTIONS_PER_PAGE,
  useAllHomeFeed,
} from '@/modules/home/hooks/useAllHomeFeed';
import { getSdk as getAllHomeFeedSdk } from '@/modules/home/queries/AllHomeFeed.delio.sdk.generated';
import { RichLayout } from '@/modules/layout/components/Layouts/RichLayout';
import { useShowLocationBanner } from '@/modules/location/hooks/useShowLocationBanner';
import { InfiniteScroll } from '@/modules/product/components/ProductsForQuery/components/InfinityScroll';
import { navbarRequests } from '@/navbarRequests';

import { DelioPage } from './types';

const LocationBanner = dynamic(
  async () =>
    (await import('@/modules/home/components/LocationBanner')).LocationBanner
);

const Index: DelioPage = () => {
  const allHomeFeed = useAllHomeFeed();
  const { showLocationBanner } = useShowLocationBanner();

  const { query, replace } = useRouter();

  // We need it for the case when user has no cart and try to go to checkout
  // for example if user add something to cart and then pay on mobile application and
  // then go to checkout page on web
  useEffect(() => {
    if (query?.clearCache === 'true') {
      replace('/');
    }
  }, [query, replace]);

  useEffect(() => {
    sendHomepageViewEvent();
  }, []);

  return (
    <RichLayout>
      <div className={cn('relative', 'flex', 'flex-col', 'overflow-x-hidden')}>
        {showLocationBanner && (
          <div className={cn('empty:pb-0')}>
            <LocationBanner />
          </div>
        )}
        {allHomeFeed.isLoading && !allHomeFeed.data ? (
          <HomePageSkeleton />
        ) : (
          <DynamicSections webHomeFeed={allHomeFeed.data?.webHomeFeed} />
        )}
        {allHomeFeed.isLoading && allHomeFeed.data && <HomePageSkeleton />}
        <InfiniteScroll
          disabled={allHomeFeed.isLoading}
          load={allHomeFeed.loadMore}
        />
      </div>
      <UvpSection />
    </RichLayout>
  );
};

export const getServerSideProps = getDelioServerSideProps(
  ({ req, res, query }, { authRole }) =>
    serializeApiGatewayClient({
      client: createDynamicApiGatewayClient({
        req,
        res,
      }),
      handleRequests: async (client) => {
        const offset = query.offset ? parseInt(query.offset as string, 10) : 0;

        await navbarRequests({
          authRole,
          client,
          metadataPathName: '/',
          requests: (coordinates) => [
            getAllHomeFeedSdk(client).allHomeFeedQuery({
              variables: {
                coordinates,
                limit: offset || SECTIONS_PER_PAGE,
              },
              context: { clientName: 'delio' },
            }),
          ],
        });
      },
    }),
  ['home']
);

export default Index;
