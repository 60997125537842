import { useMemo } from 'react';

import { useSlider } from '@/core/hooks/useSlider';
import { BannersArrows } from '@/core/ui/components/BannersArrows/BannersArrows';
import { BannersDots } from '@/core/ui/components/BannersDots/BannersDots';
import { sendBannerSelectEvent } from '@/modules/analytics/eventHandlers/sendBannerSelectEvent';

import { BannersUspSectionSlideDesktop } from './components/BannersUspSectionSlideDesktop/BannersUspSectionSlideDesktop';
import { BannersUspSectionSlideMobile } from './components/BannersUspSectionSlideMobile/BannersUspSectionSlideMobile';
import { BannersUspSectionSlideProps } from './services/types';

type BannersUspProps = {
  banners: BannersUspSectionSlideProps[];
};

type HandleClickArgs = Pick<
  BannersUspSectionSlideProps,
  'title' | 'desktopImageUrl' | 'link'
> & {
  type?: string;
  index: number;
};

export const BannersUsp = ({ banners }: BannersUspProps) => {
  const durations = useMemo(
    () => banners?.map(({ replaceIn }) => replaceIn),
    [banners]
  );

  const {
    sliderRef,
    handleDotClick,
    handlePrev,
    handleNext,
    dotsLength,
    showControls,
    currentSlide,
    isPrevDisabled,
    isNextDisabled,
  } = useSlider({
    // Avoid loop if there is only one banner
    loop: banners?.length > 1,
    initial: 0,
    durations,
  });

  const handleClick = ({
    link,
    title,
    desktopImageUrl,
    index,
    type,
  }: HandleClickArgs) => {
    sendBannerSelectEvent({
      link,
      title: title ?? undefined,
      backgroundImage: String(desktopImageUrl),
      type: String(type),
      position: index + 1,
    });
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    args: HandleClickArgs
  ) => {
    if (e.key === 'Enter') {
      handleClick({ ...args });
    }
  };

  return (
    <>
      <div className="relative" data-testid="usb-banners">
        <div ref={sliderRef} className="keen-slider">
          {banners.map(
            (
              {
                bannerBackgroundColor,
                iconBackgroundColor,
                iconUrl,
                desktopImageUrl,
                subtitle,
                link,
                title,
                ctaLabel,
                __typename,
              },
              index
            ) => (
              <div
                tabIndex={0}
                role="button"
                className="keen-slider__slide min-w-full"
                key={index}
                aria-label={title ?? ''}
                onKeyDown={(e) => {
                  handleKeyDown(e, {
                    index,
                    desktopImageUrl,
                    title,
                    type: __typename,
                    link,
                  });
                }}
                onClick={() =>
                  handleClick({
                    index,
                    desktopImageUrl,
                    title,
                    type: __typename,
                    link,
                  })
                }
              >
                <div className="hidden lg:contents">
                  <BannersUspSectionSlideDesktop
                    bannerBackgroundColor={bannerBackgroundColor}
                    desktopImageUrl={desktopImageUrl}
                    title={title}
                    subtitle={subtitle}
                    ctaLabel={ctaLabel}
                    link={link}
                  />
                </div>
                <div className="contents lg:hidden">
                  <BannersUspSectionSlideMobile
                    bannerBackgroundColor={bannerBackgroundColor}
                    title={title}
                    ctaLabel={ctaLabel}
                    link={link}
                    iconBackgroundColor={iconBackgroundColor}
                    iconUrl={iconUrl}
                  />
                </div>
              </div>
            )
          )}
        </div>
        {showControls && (
          <BannersArrows
            className="sm:hidden lg:block"
            onPrev={handlePrev}
            onNext={handleNext}
            isPrevDisabled={isPrevDisabled}
            isNextDisabled={isNextDisabled}
          />
        )}
      </div>
      {showControls && dotsLength && (
        <BannersDots
          currentSlide={currentSlide}
          totalSlides={dotsLength}
          onClick={handleDotClick}
        />
      )}
    </>
  );
};
