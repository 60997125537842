import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDebouncedCallback } from 'use-debounce';

export const InfiniteScroll = ({
  load,
  disabled,
}: {
  load: () => Promise<void>;
  disabled?: boolean;
}) => {
  const [ref, inView] = useInView();

  const loadDebounced = useDebouncedCallback(load, debounceTime);

  useEffect(() => {
    if (inView && !disabled) {
      loadDebounced();
    }
  }, [inView, loadDebounced, disabled]);

  return <div ref={ref} />;
};

const debounceTime = 300;
