import Image from 'next/image';

import { WithLink } from '@/modules/layout/components/WithLink';
import { FCC } from '@/types/common';
import { Button } from '@lib/theme/components/Button';

import { NATURAL_SLIDE_HEIGHT } from '../../services/constants';
import { BannersUspSectionSlideProps } from '../../services/types';

export const BannersUspSectionSlideDesktop: FCC<
  BannersUspSectionSlideProps
> = ({
  bannerBackgroundColor,
  desktopImageUrl,
  title,
  ctaLabel,
  subtitle,
  link,
}) => (
  <WithLink href={link}>
    <div
      className="flex items-center overflow-hidden rounded-xl"
      style={{
        backgroundColor: bannerBackgroundColor,
        height: NATURAL_SLIDE_HEIGHT,
      }}
    >
      <div className="w-full px-12 py-9">
        <h3 className="font-serif text-3xl font-medium italic text-primary-900">
          {title}
        </h3>
        {subtitle && (
          <h4 className="mt-3 text-lg text-primary-900">{subtitle}</h4>
        )}
        {ctaLabel && (
          <div className="mt-4">
            <Button color="tertiary/gray/800">{ctaLabel}</Button>
          </div>
        )}
      </div>
      <div className="relative h-[216px] w-[380px] min-w-[380px] max-w-full lxl:w-[620px] lxl:min-w-[620px]">
        <Image
          fill
          className="object-cover object-left lxl:object-[unset]"
          src={desktopImageUrl}
          alt={title || 'USP banner slide'}
          sizes="(max-width: 1440px) 380px, 620px"
        />
      </div>
    </div>
  </WithLink>
);
