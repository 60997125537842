import dynamic from 'next/dynamic';
import { ReactNode } from 'react';

import { TextAndImageSection } from '@/core/ui/components/TextAndImageSection/TextAndImageSection';
import { cn } from '@/core/ui/utils';
import { BannersSection } from '@/modules/home/components/BannersSection/BannersSection';
import { ProductsSection } from '@/modules/home/components/ProductsSection/ProductsSection';
import { PromotionsSection } from '@/modules/home/components/PromotionsSection';

import { AllHomeFeedQuery } from '../../queries/AllHomeFeed.delio.generated';
import { HomeFeedSection } from '../../queries/types';

const BannersUspSection = dynamic(
  async () =>
    (await import('../BannersUspSection/BannersUspSection')).BannersUspSection
);

const CategoryProductsSection = dynamic(
  async () =>
    (await import('../CategoryProductsSection/CategoryProductsSection'))
      .CategoryProductsSection
);

const PromotionProductsSection = dynamic(
  async () =>
    (await import('../PromotionProductsSection/PromotionProductsSection'))
      .PromotionProductsSection
);

const RecentOrderSection = dynamic(
  async () =>
    (await import('../RecentOrderSection/RecentOrderSection'))
      .RecentOrderSection
);
const SplitUspBannerSection = dynamic(
  async () =>
    (await import('../SplitUspBannerSection/SplitUspBannerSection'))
      .SplitUspBannerSection
);

const getDynamicSection = (
  section: HomeFeedSection
): {
  component: ReactNode;
  className?: string;
} => {
  switch (section.__typename) {
    case 'WebBannerCarousel':
      return {
        component: section.banners.length > 0 && (
          <div className="px-4 sm:px-0">
            <BannersSection section={section} />
          </div>
        ),
      };
    case 'ProductsSection':
      return {
        component: section.products.length > 0 && (
          <ProductsSection section={section} />
        ),
      };
    case 'PromotionsSection': {
      return {
        component: section.promotions.length > 0 && (
          <PromotionsSection section={section} />
        ),
        className: 'sm:hidden',
      };
    }
    case 'PromotionProductsSection':
      return {
        component: section.products.length > 0 && (
          <PromotionProductsSection section={section} />
        ),
      };
    case 'PreviouslyOrderedProductsSection':
      return {
        component: section.products.length > 0 && (
          <RecentOrderSection section={section} />
        ),
      };
    case 'WebUspBannerCarousel':
      return {
        component: section.banners.length > 0 && (
          <BannersUspSection section={section} />
        ),
      };
    case 'CategoryProductsSection':
      return {
        component: section.products.length > 0 && (
          <CategoryProductsSection section={section} />
        ),
      };
    case 'TextAndImageSection':
      return {
        component: section.id && <TextAndImageSection section={section} />,
      };
    case 'WebSplitUspBannerCarousel':
      return {
        component: section.banners.length > 0 && (
          <SplitUspBannerSection section={section} />
        ),
      };
    default:
      return { component: null };
  }
};

export const DynamicSections = ({
  webHomeFeed,
}: {
  webHomeFeed: AllHomeFeedQuery['webHomeFeed'] | undefined;
}) => {
  if (!webHomeFeed) return;
  const sectionLength = Number(webHomeFeed.sections.length);

  return webHomeFeed.sections.map((section, index) => {
    const isFirstSection = index === 0;
    const isLastSection = index === sectionLength - 1;
    const isBanner =
      section.__typename === 'WebBannerCarousel' ||
      section.__typename === 'WebUspBannerCarousel';

    const { component, className } = getDynamicSection(section);

    return (
      <div key={index} className={cn('mx-auto', 'w-full', 'max-w-7xl')}>
        <div
          className={cn(
            'empty:p-0',
            'py-3 md:py-4 lxl:py-6',
            isFirstSection && 'pt-4 md:pt-6 lxl:pt-8',
            isLastSection && 'pb-10 md:pb-12 lxl:pb-16',
            isBanner && !isFirstSection && 'lxl:py-10',
            isBanner && isLastSection && 'lxl:pt-10',
            className
          )}
        >
          {component}
        </div>
      </div>
    );
  });
};
