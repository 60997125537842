import { useRouter } from 'next/router';
import { useCallback, useMemo, useRef } from 'react';

import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';

import { useAllHomeFeedQuery } from '../queries/AllHomeFeed.delio.generated';

export const SECTIONS_PER_PAGE = 4;

export const useAllHomeFeed = () => {
  const {
    coordinates,
    error: coordinatesError,
    loading: coordinatesLoading,
  } = useUserAddressCoordinates();
  const { query, replace } = useRouter();
  const hasMore = useRef(true);

  const offset = query.offset ? parseInt(query.offset as string, 10) : 0;

  const initialVariables = useRef({ offset });

  const allHomeFeedResult = useAllHomeFeedQuery({
    variables: {
      coordinates,
      limit: initialVariables.current.offset || SECTIONS_PER_PAGE,
    },
    skip: coordinatesLoading,
    notifyOnNetworkStatusChange: true,
  });

  const { data, fetchMore } = allHomeFeedResult;

  const error = allHomeFeedResult.error ?? coordinatesError;
  const isLoading = allHomeFeedResult.loading || coordinatesLoading;

  const loadMore = useCallback(async () => {
    if (!hasMore.current) {
      return;
    }
    const fetchMoreOffset = offset || SECTIONS_PER_PAGE;
    const result = await fetchMore({
      variables: {
        offset: fetchMoreOffset,
        limit: SECTIONS_PER_PAGE,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (fetchMoreResult.webHomeFeed.sections.length === 0) {
          hasMore.current = false;

          return prev;
        }
        return {
          __typename: 'Query',
          webHomeFeed: {
            ...prev.webHomeFeed,
            sections: [
              ...prev.webHomeFeed.sections,
              ...fetchMoreResult.webHomeFeed.sections,
            ],
          },
        };
      },
    });

    if (result.data.webHomeFeed.sections.length === 0) {
      return;
    }

    await replace(
      {
        query: { ...query, offset: fetchMoreOffset + SECTIONS_PER_PAGE },
      },
      undefined,
      { shallow: true }
    );
  }, [fetchMore, offset, query, replace]);

  return useMemo(
    () => ({
      data,
      isLoading,
      error: data ? undefined : error,
      loadMore,
    }),
    [data, error, isLoading, loadMore]
  );
};
